document.addEventListener("DOMContentLoaded", function () {


     handleMobileNavigation();


     let advBlock = document.getElementById('adv-block')
     const plane = document.getElementById('plane');
     const ship = document.getElementById('ship');
     const waves = document.getElementById('waves');
     const uhrzeiger = document.getElementById('uhrzeiger-pfad');

     let yachtBlock = document.getElementById('kreuzfahrt-teaser')
     let yacht=document.getElementById('yacht');


     const observer = new IntersectionObserver(entries => {
       entries.forEach(entry => {
         if (entry.isIntersecting) {
           ship.classList.add('animated');
           waves.classList.add('animated');
           uhrzeiger.classList.add('animated');
           plane.classList.add('animated');
         }
       });
     });

     const observer2 = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            yacht.classList.add('animated');

          }
        });
      });
   
	if(advBlock){
     observer.observe(advBlock);
	}
	if(yachtBlock){
     observer2.observe(yachtBlock);
	}



    let navItems = document.querySelectorAll(".has-items");

    navItems.forEach(function (e) {
        e.addEventListener("click", function (e) {
            e.target.classList.toggle("is-active");
            e.target.nextElementSibling.classList.toggle("subitems-active");
        });
    });

    function handleMobileNavigation() {
        //Get Mobile Navigation components
        let navBtn = document.getElementById("nav-btn");
        let wrapper = document.getElementById("navigation-wrapper");
        let mobileNav = document.getElementById("mobile-navigation");

        if (window.innerWidth < 1280) {
            navBtn.addEventListener("click", function () {
                wrapper.classList.toggle("active");
                mobileNav.classList.toggle("wrapper-active");
            });
        }

        if (window.innerWidth >= 1280) {
            let isHidden = wrapper.classList.contains("hidden");
            if (!isHidden) {
                wrapper.classList.add("hidden");
            }
        }
    }
});
